<template>
  <div class="music-player">
   <div class="control-buttons">
     <i @click="previousSong" class="fas fa-backward"></i>
      <div @click="togglePlay">
        <i v-if="isPlaying" class="fas fa-pause"></i>
        <i v-else class="fas fa-play"></i>
      </div>
      <i @click="nextSong" class="fas fa-forward"></i>
   </div>
    <audio 
      :src="audioSrc" 
      ref="audioPlayer"
      :autoplay="isPlaying"
      @ended="endSong"
    ></audio>
  </div>
</template>

<script>
export default {
  name: 'music-player',
  props: {
  },
  data() {
    return {
      audioPlayer: null, 
    }
  },
  created() {
    document.addEventListener('keydown', this.handleKeypress);
  },
  mounted() {
    this.audioPlayer = this.$refs.audioPlayer;
  },
  computed: {
    currentSong() {
      return this.$store.state.currentSong;
    },
    isPlaying() {
      return this.$store.state.isPlaying;
    },
    audioSrc() {
      if (!this.currentSong) return '';
      return require(`../assets/mp3s/${this.currentSong}.mp3`);
    }, 
    isValidSrc() {
      const validFileTypes = ['mp3']; 
      const fileType = this.audioSrc.slice(-3);
      return validFileTypes.includes(fileType);
    },
  },
  methods: {
    togglePlay() {
      const player = this.audioPlayer; 
      if (!player) return;
      if (this.isValidSrc) {
        const isPaused = player.paused; 
        isPaused ? player.play() : player.pause();
      }
      this.$store.commit('togglePlay');
    },
    handleKeypress(e) {
      const keyCode = e.code; 
      const codes = ["Space", "ArrowLeft", "ArrowRight"];
      if (codes.includes(keyCode)) e.preventDefault();
      switch(keyCode) {
        case "Space": 
          this.togglePlay(); 
          break;
        case "ArrowLeft": 
          this.previousSong(); 
          break;
        case "ArrowRight": 
          this.nextSong(0); 
          break;
        default: 
          break;
      }
    },
    previousSong() {
     this.$store.commit('previousSong');
    },
    nextSong() {
      this.$store.commit('nextSong');
    },
    endSong() {
      this.$store.commit('togglePlay');
    },
  },
}
</script>

<style>
  .music-player {
    width: 100%;
    height: 10vh; 
    background-color: rgb(40, 40, 40); 
    position: fixed; 
    bottom: 0;
    color: white; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
  }

  .control-buttons {
    display: flex; 
    align-items: center;
  }

  .control-buttons i {
    margin: 0px 40px;
  }
</style>
