<template>
  <div id="app">
    <div class="blocks">
      <neuro-computer></neuro-computer>
      <song song="Calm"></song>
      <song song="OSC TEST"></song>
      <song song="Prozinator 2"></song>
      <song song="The Light Bulb Blew"></song>
      <song song="EXS 24"></song>
      <song song="Sleep Chant"></song>
      <song song="Yashiro Telepath"></song>
      <song song="It's Breathing"></song>
      <song song="Late"></song>
      <song song="8D8"></song>
      <song song="Puzzle"></song>
      <song song="Sleep Chant 10"></song>
      <song song="Alone Again"></song>
      <song song="Depth"></song>
      <song song="Awake"></song>
      <song song="Sleep Chant 7"></song>
      <!-- <text-block text="You are the blood of the internet"></text-block> -->
      <song song="sunrise.zip"></song>
    </div>
    <music-player></music-player>
  </div>
</template>

<script>
import MusicPlayer from "./components/music-player.vue";
import NeuroComputer from "./components/neuro-computer.vue";
import Song from "./components/song.vue";
// import TextBlock from "./components/text-block.vue";

export default {
  name: 'App',
  components: {
    "song": Song,
    "music-player": MusicPlayer,
    "neuro-computer": NeuroComputer,
    // "text-block": TextBlock,
  },
}
</script>

<style>
  html {
    background-color: rgb(176, 44, 236);
  }

  body {
    background-image: url('./assets/siteGraphics/background.gif');
  }

  @media screen and (min-width: 768px) {
    #app {
      display: flex; 
      flex-direction: column; 
      align-items: center; 
    }
  }

  .blocks {
    display: flex;
    flex-direction: column;  
    align-items: center;
  }

  @media screen and (min-width: 768px) {
    .blocks {
      width: 80%;
      max-width: 800px;
    }
  }
</style>