<template>
  <div class="neuro-computer">
    <img 
      :src="imageSrc" 
      class="neuro-computer-image img-fluid" 
      @click="toggleNeuroComputer"
    />
    <audio :src="audioSrc" :loop="true" ref="audioPlayer"></audio>
  </div>
</template>

<script>
export default {
  name: 'neuro-computer',
  data() {
    return {
      audioPlayer: null,
    } 
  },
  mounted() {
    this.audioPlayer = this.$refs.audioPlayer;
  },
  computed: {
    imageSrc() {
      return require(`../assets/siteGraphics/neuroComputer.gif`);
    },
    audioSrc() {
      return require(`../assets/soundEffects/neuroComputer.mp3`);
    },
  },
  methods: {
    toggleNeuroComputer() {
      const player = this.audioPlayer;
      const isPaused = player.paused; 
      isPaused ? player.play() : player.pause();
    },
  }
}
</script>

<style>
  .neuro-computer {
    width: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    margin: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 50px 50px 50px;
    padding-top: 30px;
    background-color: transparent;
  }

  .neuro-computer-image {
    width: 400px;
    cursor: pointer;
  }
</style>
