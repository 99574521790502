<template>
  <div class="song">
  <div class="song-title">[  {{ song }}  ]</div>
    <img 
      :src="imageSrc" 
      class="song-image img-fluid" 
      @click="updateCurrentSongAndTogglePlay"
    />
    <a :href="downloadSrc" class="download-link" download>Download MP3</a>
  </div>
</template>

<script>
export default {
  name: 'song',
  props: {
    song: {
      required: true, 
    },
  },
  data() {
    return {
      audioPlayer: null,
    } 
  },
  mounted() {
    this.audioPlayer = this.$refs.audioPlayer;
  },
  computed: {
    imageSrc() {
      let extension;
      switch(this.song) {
        case "Calm":
          extension = ".png";
          break; 
        default:
          extension = ".jpg";
          break; 
      }
      return require(`../assets/images/${this.song}${extension}`);
    },
    downloadSrc() {
      return require(`../assets/mp3s/${this.song}.mp3`);
    },
  },
  methods: {
    updateCurrentSongAndTogglePlay() {
      const currentSong = this.$store.state.currentSong;
      if (currentSong === this.song) return;
      this.$store.commit('updateCurrentSong', this.song);
    }
  }
}
</script>

<style>
  .song {
    width: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    margin: 50px;
    margin-top: 0px;
    padding: 0px 50px 50px 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: black;
    color: white;
    font-family: 'VT323', monospace;
    font-size: 20px;
  }

  .song-image {
    width: 400px;
    cursor: pointer;
  }

  .song-title {
    text-align: center;
    margin-bottom: 20px;
  }

  .download-link {
    color: rgb(176, 44, 236);
    margin-top: 20px; 
    font-size: 16px;
  }
</style>
