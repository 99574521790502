import Vue from "vue";
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentSong: null, 
    isPlaying: false, 
    songs: [  
      'Calm',
      'OSC TEST',
      'Prozinator 2',
      'The Light Bulb Blew',
      'EXS 24',
      'Sleep Chant',
      'Yashiro Telepath',
      "It's Breathing",
      'Late',
      '8D8',
      'Puzzle',
      'Sleep Chant 10',
      'Alone Again',
      'Depth',
      'Awake',
      'Sleep Chant 7',
      'sunrise.zip',
    ],
  },
  mutations: {
    updateCurrentSong(state, newSong) {
      if (state.currentSong === newSong) return; 
      state.currentSong = newSong;
      state.isPlaying = true;
    },
    togglePlay(state) {
      if (!state.currentSong) state.currentSong = state.songs[0];
      state.isPlaying = !state.isPlaying;
    },
    previousSong(state) {
      const currentSong = state.currentSong; 
      if (!currentSong) return; 
      const songs = state.songs; 
      const currentIndex = songs.indexOf(currentSong);
      if (currentIndex === 0) return; 
      const newSong = songs[currentIndex - 1];
      state.currentSong = newSong;
    }, 
    nextSong(state) {
      const currentSong = state.currentSong;
      if (!currentSong) return;
      const songs = state.songs;
      const currentIndex = songs.indexOf(currentSong);
      const newSong = songs[currentIndex + 1];
      if (newSong) {
        state.currentSong = newSong;
      } else {
        state.isPlaying = false; 
        state.currentSong = null;
      }
    },
  }
})